<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top flex-space-between">
          <el-page-header @back="goBack" :content="type == 1 ? '订单详情' : '退费详情'"> </el-page-header>
          <div class="flex-end">
            <div style="margin-left: 10px" v-if="type == 1 && (detailInfo.state == 1 || detailInfo.state == 3 || detailInfo.state == -3 || detailInfo.state == 4 || detailInfo.state == 5 || detailInfo.state == 6 || detailInfo.state == 7)">
              <!-- <el-button type="primary" size="small" @click="toPrint($refs.pdfDomQT)" v-if="mZPrescriptionInfo.mzOtherCharges.length > 0 || mZPrescriptionInfo.mzCheckItems.length > 0">打印其他费用</el-button> -->
              <el-button type="primary" size="small" @click="toPrint($refs.pdfDomFJ)" v-if="mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionYP.length > 0">打印方剂处方</el-button>
              <el-button type="primary" size="small" @click="toPrint($refs.pdfDomCY)" v-if="mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionCY.length > 0">打印成药处方</el-button>
              <el-button type="primary" size="small" @click="toPrint($refs.pdfDomSY)" v-if="mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionSYGroup.length > 0">打印输液处方</el-button>
            </div>
            <div style="margin-left: 10px" v-if="type == 1 && (detailInfo.state == 3 || detailInfo.state == 4 || detailInfo.state == 5 || detailInfo.state == 6 || detailInfo.state == 7)">
              <el-button type="warning" size="small" @click="toPrint($refs.useDesc)">打印服用说明</el-button>
            </div>
            <div style="margin-left: 10px" v-if="type == 1 && (detailInfo.state == 1 || detailInfo.state == 3 || detailInfo.state == -3 || detailInfo.state == 4 || detailInfo.state == 5 || detailInfo.state == 6 || detailInfo.state == 7)">
              <el-button type="success" size="small" @click="toPrint($refs.payList)">打印收费凭证</el-button>
            </div>

            <!-- <el-button type="primary" size="small" @click="toCharge"
              v-if="type == 1 && detailInfo.state == 0">收费</el-button> -->
          </div>
        </div>
        <div class="table-line"></div>
        <div class="table-content">
          <div class="top-info flex-space-between">
            <div class="left-i">
              <div class="flex-start">
                <img v-if="patientInfo.sex" src="@/assets/img/touxiang03.png" />
                <img v-else src="@/assets/img/touxiang04.png" />
                <div class="userName">
                  {{ mZPrescriptionInfo.mzPrescriptionOut.patientName ? mZPrescriptionInfo.mzPrescriptionOut.patientName : '无' }}
                </div>
              </div>
              <div class="info-div">
                <span class="t1"
                  ><span class="t2">性别：</span><span v-if="patientId">{{ mZPrescriptionInfo.mzPrescriptionOut.sex ? '男' : '女' }}</span
                  ><span v-else>无</span></span
                >
                <span class="t1"><span class="t2">年龄：</span>{{ mZPrescriptionInfo.mzPrescriptionOut.age ? mZPrescriptionInfo.mzPrescriptionOut.age : '无' }}</span>
                <span><i class="el-icon-phone t2"></i>：{{ mZPrescriptionInfo.mzPrescriptionOut.patientPhone ? mZPrescriptionInfo.mzPrescriptionOut.patientPhone : '无' }}</span>
              </div>
            </div>
            <div class="center-i">
              <div class="t1">
                <span class="t2">就诊类型：</span>
                <template v-if="detailInfo.diagnoseType">复诊</template>
                <template v-else>初诊</template>
              </div>
              <div class="t1">
                <span class="t2">就诊时间：</span>
                <span>{{ mZPrescriptionInfo.mzPrescriptionOut.addTime | timefilters }}</span>
              </div>
              <div class="t1">
                <span class="t2">看诊医生：</span>
                <span>{{ detailInfo.doctorName ? detailInfo.doctorName : '无' }}</span>
                <span v-if="mZPrescriptionInfo.mzPrescriptionOut.userPhone">【{{ mZPrescriptionInfo.mzPrescriptionOut.userPhone }}】</span>
              </div>
            </div>
            <div class="right-i">
              <div class="t1">
                <span class="t2">审核医生：</span>
                <span>{{ mZPrintPrescription.doctorName ? mZPrintPrescription.doctorName : '无' }}</span>
                <span v-if="mZPrintPrescription.doctorPhone">【{{ mZPrintPrescription.doctorPhone }}】</span>
                
              </div>
              <div class="t1">
                <span class="t2">审核时间：</span>
                <span v-if="mZPrescriptionInfo.mzPrescriptionOut.auditTime">{{ mZPrescriptionInfo.mzPrescriptionOut.auditTime | timefilters }}</span>
              </div>
              <!-- <div class="t1">
                <span class="t2">药剂师：</span>
                <span>{{ mZPrintPrescription.pharmacistName ? mZPrintPrescription.pharmacistName : '无' }}</span>
                <span v-if="mZPrintPrescription.pharmacistPhone">【{{ mZPrintPrescription.pharmacistPhone }}】</span>
              </div> -->
              <div class="t1">
                <span class="t2">诊断：</span>
                <span>{{ detailInfo.diagnoseResult ? detailInfo.diagnoseResult : '无' }}</span>
              </div>
            </div>
            <div class="right-img">
              <img v-if="detailInfo.state == 1 && detailInfo.refundMoney == 0" src="@/assets/img/image_paid.png" />
              <img v-if="detailInfo.state == -1" src="@/assets/img/image_refund.png" />
              <img v-if="detailInfo.state == 1 && detailInfo.refundMoney > 0 && detailInfo.refundMoney < detailInfo.totalPayMoney" src="@/assets/img/image_refundBuFen.png" />
            </div>
          </div>
          <div class="numDiv2 flex-space-between">
            <div class="flex-start">
              <div class="t1">
                总金额：¥{{ detailInfo.totalMoney ? detailInfo.totalMoney.toFixed(2) : '0.00' }}
                <!-- - 优惠：¥{{
                detailInfo.discountsMoney ? detailInfo.discountsMoney.toFixed(2) : '0.00' }} = 应收：¥{{
    (detailInfo.totalMoney -
      detailInfo.discountsMoney).toFixed(2) }} -->
              </div>
            </div>
            <!-- <div class="infoDiv flex-end" v-if="detailInfo.state == 3">
              <div class="t1" v-if="detailInfo.auditMoney">审方金额：¥{{ detailInfo.auditMoney || '0.00'}}</div>
              <div class="t1">平台抽佣：¥{{ detailInfo.platformCommissionMoney || '0.00'}}</div>
              <div class="t1">医生分账：¥{{ detailInfo.doctorDIstributionMoney || '0.00'}}</div>
              <div class="t1">机构入账：¥{{ detailInfo.orgTotalMoney || '0.00'}}</div>
            </div> -->
            <i class="el-icon-caret-top" @click="isShow = false" v-if="isShow"></i>
            <i class="el-icon-caret-bottom" @click="isShow = true" v-else></i>
          </div>
          <!-- <div class="infoDiv flex-space-between">
            <div>
              <span class="t1">取药方式：</span>
              <span class="t2" v-if="detailInfo.isSelef">自取 </span>
              <span class="t2" v-else>快递 收件人信息：{{ detailInfo.takeAddress ? detailInfo.takeAddress : '无' }} </span>
            </div>
          </div> -->
          <div style="background: #f7f8fa; border-radius: 4px; padding: 0 0 20px 20px" v-if="isShow">
            <el-row type="flex" justify="start">
              <el-col :span="6" v-if="orderGrouped[0] && orderGrouped[0].length > 0">
                <div class="flex-start">
                  <img src="@/assets/img/dd_icon01.png" style="width: 12x; height: 12x; margin-right: 5px" />
                  <div class="title" style="color: #444444; margin-right: 5px">处方费</div>
                  <el-tag type="success" size="mini" v-if="detailInfo.state > 0 || detailInfo.state == -3">已支付</el-tag>
                  <el-tag type="danger" size="mini" v-if="detailInfo.state == -1 || detailInfo.state == -4">已退费</el-tag>
                </div>
                <div v-if="orderGrouped[0] && orderGrouped[0].length > 0">· 直接售药 ------ ￥{{ totalSale }}</div>
              </el-col>
              <el-col :span="6" v-if="orderGrouped[1] && orderGrouped[1].length > 0">
                <div class="flex-start">
                  <img src="@/assets/img/dd_icon01.png" style="width: 12x; height: 12x; margin-right: 5px" />
                  <div class="title" style="color: #444444; margin-right: 5px">处方费</div>
                  <el-tag type="success" size="mini" v-if="detailInfo.state == -3">已支付</el-tag>
                  <el-tag type="danger" size="mini" v-if="detailInfo.state == -1 || detailInfo.state == -4">已退费</el-tag>
                  <el-tag type="success" size="mini" v-if="detailInfo.state == 5">已发货</el-tag>
                  <el-tag type="warning" size="mini" v-if="detailInfo.state == 4">取药完成</el-tag>
                  <el-tag type="warning" size="mini" v-if="detailInfo.state == 3 && !detailInfo.isSelef">待发货</el-tag>
                  <el-tag type="warning" size="mini" v-if="detailInfo.state == 3 && detailInfo.isSelef">待取药</el-tag>

                </div>
                <div v-if="prescriptionGrouped[0] && prescriptionGrouped[0].length > 0">· 方剂处方 ------ ￥{{ totalFJ }}</div>
                <div v-if="prescriptionGrouped[1] && prescriptionGrouped[1].length > 0">· 成药处方 ------ ￥{{ totalCY }}</div>
                <div v-if="prescriptionGrouped[2] && prescriptionGrouped[2].length > 0">· 输液处方 ------ ￥{{ totalSY }}</div>
              </el-col>
              <el-col :span="6" v-if="orderGrouped[88] && orderGrouped[88].length > 0">
                <div class="flex-start">
                  <img src="@/assets/img/dd_icon02.png" style="width: 12x; height: 12x; margin-right: 5px" />
                  <span class="title" style="color: #444444; margin-right: 5px">审方费</span>
                  <span class="title" style="color: #ff0000; margin-right: 5px">（不可退）</span>
                </div>
                <div v-for="(item, sf) in orderGrouped[88]" :key="sf">· {{ item.entityName }} ------ ￥{{ item.countMoney.toFixed(2) }}</div>
              </el-col>
              <el-col :span="6" v-if="orderGrouped[2].length > 0">
                <div class="flex-start">
                  <img src="@/assets/img/dd_icon03.png" style="width: 12x; height: 12x; margin-right: 5px" />
                  <span class="title" style="color: #444444; margin-right: 5px">检查治疗项</span>
                  <span class="title" style="color: #ff0000; margin-right: 5px">（不可退）</span>
                </div>
                <div v-for="(item, jc) in orderGrouped[2]" :key="jc">· {{ item.entityName }} ------ ￥{{ item.countMoney.toFixed(2) }}</div>
              </el-col>
              <el-col :span="6" v-if="orderGrouped[99] && orderGrouped[99].length > 0">
                <div class="flex-start">
                  <img src="@/assets/img/dd_icon04.png" style="width: 12x; height: 12x; margin-right: 5px" />
                  <span class="title" style="color: #444444; margin-right: 5px">其他收费项</span>
                  <el-tag type="success" size="mini" v-if="detailInfo.state > 0 || detailInfo.state == -3">已支付</el-tag>
                  <el-tag type="danger" size="mini" v-if="detailInfo.state == -1 || detailInfo.state == -4">已退费</el-tag>
                </div>
                <div v-for="(item, qt) in orderGrouped[99]" :key="qt">· {{ item.entityName }} ------ ￥{{ item.countMoney.toFixed(2) }}</div>
              </el-col>
            </el-row>
          </div>

          <!-- <el-divider></el-divider> -->
          <div v-if="orderGrouped[1].length > 0 || orderGrouped[0].length > 0 || orderGrouped[2].length > 0 || orderGrouped[88].length > 0 || orderGrouped[99].length > 0" class="flex-space-between" style="margin-bottom: -20px">
            <div class="flex-start">
              <img src="@/assets/img/dd_icon05.png" style="width: 12x; height: 12x; margin-right: 5px" />
              <div class="title" style="font-size: 18px; color: #444444">处方订单详情</div>
            </div>
            <div class="flex-end">
              <span class="t1">是否快递：</span>
              <el-radio v-model="detailInfo.isSelef" v-if="detailInfo.isSelef" :label="true">自取</el-radio>
              <el-radio v-model="detailInfo.isSelef" v-else :label="false"
                >快递 <span style="margin-left: 10px; color: #666666">{{ detailInfo.takeAddress ? detailInfo.takeAddress : '无' }}</span></el-radio
              >
              <!-- <span class="t2" v-if="detailInfo.isSelef">自取 </span>
              <span class="t2" v-else>快递 收件人信息：{{ detailInfo.takeAddress ? detailInfo.takeAddress : '无' }} </span> -->
            </div>
          </div>

          <div class="title" v-if="orderGrouped[1].length > 0">处方药</div>
          <el-table :data="orderGrouped[1]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[1].length > 0">
            <el-table-column prop="prescriptionType" label="处方类型">
              <template slot-scope="scope">
                <p v-if="scope.row.prescriptionType == 0">方剂处方</p>
                <p v-if="scope.row.prescriptionType == 1">成药处方</p>
                <p v-if="scope.row.prescriptionType == 2">输液处方</p>
              </template>
            </el-table-column>
            <el-table-column prop="prescriptionName" label="处方名称" align="center"></el-table-column>
            <el-table-column prop="entityName" label="药品名称" align="center">
              <template slot-scope="scope">
                {{ scope.row.entityName }}{{scope.row.remark?`（${scope.row.remark}）`:''}}
              </template>
            </el-table-column>
            <el-table-column prop="specification" label="规格" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.specification">{{ scope.row.specification }}/{{ scope.row.specificationUnit }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="productFactory" label="厂家" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="money" label="单价(元)" align="center">
              <template slot-scope="scope"> {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center">
              <template slot-scope="scope"> {{ scope.row.amount }}{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
            </el-table-column>
            <el-table-column prop="countMoney" label="总金额(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="dosage" label="剂数" align="center">
              <template slot-scope="scope">
                {{ scope.row.dosage ? scope.row.dosage : '' }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[0] && orderGrouped[0].length > 0">直接售药</div>
          <el-table :data="orderGrouped[0]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[0].length > 0">
            <el-table-column prop="entityName" label="药品名称">
              <template slot-scope="scope">
                {{ scope.row.entityName }}
                <span v-if="scope.row.drugType == 'T'">(统)</span>
                <span v-if="scope.row.drugType == 'X'">(选)</span>
                <span v-if="scope.row.drugType == 'J'">(精选)</span>
              </template>
            </el-table-column>
            <el-table-column prop="specification" label="规格" align="center">
              <template slot-scope="scope">
                <p v-if="scope.row.specification">{{ scope.row.specification }}/{{ scope.row.specificationUnit }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="productFactory" label="厂家" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="money" label="单价(元)" align="center">
              <template slot-scope="scope"> {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center">
              <template slot-scope="scope"> {{ scope.row.amount }}{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
            </el-table-column>
            <el-table-column prop="countMoney" label="总金额(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[88] && orderGrouped[88].length > 0">审方费</div>
          <el-table :data="orderGrouped[88]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[88] && orderGrouped[88].length > 0">
            <el-table-column prop="entityName" label="费用名称">
              <template slot-scope="scope"> {{ scope.row.entityName }}<span style="color: red; font-size: 12px; margin-left: 10px" v-if="scope.row.orderType == 5 || scope.row.orderType == 8">(不可退)</span> </template>
            </el-table-column>
            <el-table-column prop="countMoney" label="金额(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[2] && orderGrouped[2].length > 0">检查治疗项</div>
          <el-table :data="orderGrouped[2]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[2].length > 0">
            <el-table-column prop="entityName" label="检查名称"></el-table-column>
            <el-table-column prop="money" label="单价(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.money.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量" align="center"></el-table-column>
            <el-table-column prop="countMoney" label="总金额(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="title" v-if="orderGrouped[99] && orderGrouped[99].length > 0">其他收费</div>
          <el-table :data="orderGrouped[99]" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" v-if="orderGrouped[99].length > 0">
            <el-table-column prop="entityName" label="费用名称">
              <template slot-scope="scope"> {{ scope.row.entityName }}<span style="color: red; font-size: 12px; margin-left: 10px" v-if="scope.row.orderType == 5 || scope.row.orderType == 8">(不可退)</span> </template>
            </el-table-column>
            <el-table-column prop="countMoney" label="金额(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.countMoney.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="状态" align="center" width="150px">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.orderState == 0" size="small">待支付</el-tag>
                <el-tag type="success" v-if="scope.row.orderState == 1" size="small">已支付</el-tag>
                <el-tag type="danger" v-if="scope.row.orderState == -1" size="small">已退费</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="numDiv">
            <div class="flex-start" v-for="(item, i) in detailInfo.payments" :key="i">
              <!-- type 付款类型 0收费 1退费 2还款 -->
              <div class="flex-start desc">
                <div class="t1">
                  <span class="t2">
                    <span v-if="item.type == 0" class="type0">收费：</span>
                    <span v-if="item.type == 1" class="type1">退费：</span>
                    <span v-if="item.type == 2" class="type2">还款：</span>
                    时间：</span
                  >{{ item.paySuccessTime | timefilters }}
                </div>
                <div><span class="t2"> 操作员：</span>{{ item.userName }}</div>
              </div>
              <div class="flex-start center-num desc">
                <!-- <div class="t1"><span class="t2">总金额：</span>{{ item.totalMoney ? item.totalMoney.toFixed(2) : '0.00' }}
                </div> -->
                <!-- <div class="t1" v-if="item.type == 0"><span class="t2">优惠：</span>¥{{ item.discountsMoney ? item.discountsMoney.toFixed(2) :
                  '0.00' }}
                </div> -->
                <div v-if="item.type == 0">
                  <span class="t2">收款金额：</span>
                  <!-- <span>¥{{ (item.totalMoney - item.discountsMoney).toFixed(2) }}</span> -->
                  <span>¥{{ item.totalMoney.toFixed(2) }}</span>
                </div>
                <div v-if="item.type == 1">
                  <span class="t2">退款金额：</span>
                  <!-- <span>¥{{ (item.totalMoney - item.discountsMoney).toFixed(2) }}</span> -->
                  <span>¥{{ item.totalMoney.toFixed(2) }}</span>
                  <!-- <span v-if="item.payMoney >= 0">¥{{ item.payMoney ? item.payMoney.toFixed(2) : '0.00' }}</span>
                  <span v-if="item.payMoney < 0">¥{{ item.totalMoney ? item.totalMoney.toFixed(2) : '0.00' }}</span> -->
                </div>
                <!-- <div v-if="item.type == 2"><span class="t2">应还：</span>¥{{ item.payMoney.toFixed(2) }}</div> -->
              </div>
              <div class="flex-end right-num desc">
                <div class="t1">
                  付款方式：
                  <!-- <span class="num2" v-for="(item, i) in detailInfo.totalPayWay">{{ i }}:¥{{ item}};</span> -->
                  <!-- <template v-if="item.type == 0">
                    <span class="t3" v-if="item.totalMoney >= item.payMoney">
                    欠款¥{{ item.debtMoney ?item.debtMoney.toFixed(2) : '0.00' }} ;</span>
                  </template> -->
                  <!-- <template v-if="item.type == 1">
                    <span class="t3" v-if="item.totalMoney >= item.payMoney">
                      抵扣¥<span v-if="item.payMoney >= 0">{{ (item.totalMoney - item.payMoney).toFixed(2) }} ;</span>
                      <span v-if="item.payMoney < 0">{{ item.totalMoney.toFixed(2) }} ;</span>
                    </span>
                    <span class="t3" v-if="item.totalMoney < item.payMoney">欠款¥{{ item.debtMoney.toFixed(2) }} ;</span>
                  </template> -->
                  <!-- <span class="t3" v-if="item.totalMoney >= item.payMoney">抵扣欠款 ¥{{ item.debtMoney ?
                    item.debtMoney.toFixed(2) : '0.00' }} ;</span>
                  <span class="t3" v-if="item.totalMoney < item.payMoney">欠款 ¥{{ item.debtMoney ?
                    item.debtMoney.toFixed(2) : '0.00' }} ;</span> -->
                  <!-- <span class="t3" v-if="item.payMoney >= 0">欠款 ¥{{ item.debtMoney ? item.debtMoney.toFixed(2) : '0.00' }} ;</span>
                  <span class="t3" v-if="item.payMoney < 0">抵扣欠款 ¥{{ item.totalMoney ? item.totalMoney.toFixed(2) : '0.00' }} ;</span> -->
                </div>
                <div class="t3">
                  <span v-if="item.payWay == 0">
                    <span v-if="detailInfo.orderType == 0">现金</span>
                    <span v-else>余额</span>
                  </span>
                  <span v-if="item.payWay == 1">微信</span>
                  <span v-if="item.payWay == 2">支付宝</span>
                  <span v-if="item.payWay == 3">银行卡</span>
                  <span v-if="item.payWay == 4">其他</span>
                  <span v-if="item.payMoney >= 0">¥{{ item.payMoney ? item.payMoney.toFixed(2) : '0.00' }}</span>
                  <span v-if="item.payMoney < 0">¥0.00</span>
                </div>
              </div>
            </div>
          </div>
          <div ref="pdfDomQT" style="width: 600px; visibility: hidden" :class="isPrint ? 'printBox' : ''">
            <div class="PrintBox" style="width: 600px; background: #ffffff; font-size: 14px; padding: 20px 20px 0">
              <div class="top">
                <div class="PrintBoxTitle">门诊处方笺</div>
                <div class="PrintBoxTitle1">
                  <div>就诊日期：{{ mZPrescriptionInfo.mzPrescriptionOut.addTime | timefilters }}</div>
                  <div>病历号:{{ mZPrintPrescription.diagnoseNumber }}</div>
                </div>
                <div class="PrintBoxTitle2">
                  <div>姓名：{{ mZPrescriptionInfo.mzPrescriptionOut.patientName }}</div>
                  <div>手机号：{{ mZPrescriptionInfo.mzPrescriptionOut.patientPhone }}</div>
                  <div>性别:{{ mZPrescriptionInfo.mzPrescriptionOut.sex ? '男' : '女' }}</div>
                  <div>年龄:{{ mZPrescriptionInfo.mzPrescriptionOut.age }}</div>
                  <div>部门:{{ mZPrintPrescription.deparment }}</div>
                </div>
                <div class="PrintBoxTitleLine"></div>
                <div>
                  <div>
                    诊断:
                    {{ mZPrescriptionInfo.mzPrescriptionOut.diseaseDiagnose }}
                  </div>
                </div>
                <div class="PrintBoxTitleLineD"></div>
                <div style="font-size: 32px">R</div>
              </div>
              <div style="height: 440px">
                <div v-for="(item, index1) in mZPrescriptionInfo.mzOtherCharges" :key="index1">
                  <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                    <span style="color: #999999">{{ item.chargeName }}:</span>
                    <span style="color: #999999; margin-left: 10px">{{ item.money }}元</span>
                  </div>
                </div>
                <div v-for="(item, index2) in mZPrescriptionInfo.mzCheckItems" :key="index2">
                  <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                    <span style="color: #999999">{{ item.itemName }}:</span>
                    <span style="color: #999999; margin-left: 10px">{{ item.tradingPrice }}元</span>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div>医嘱:{{ mZPrescriptionInfo.mzPrescriptionOut.doctorAdvice ? mZPrescriptionInfo.mzPrescriptionOut.doctorAdvice : '--' }}</div>
                <div class="PrintBoxTitleLine"></div>
                <div class="PrintBoxTitle2">
                  <div class="flex-start">医师：{{ detailInfo.doctorName ? detailInfo.doctorName : '无' }}【{{ mZPrescriptionInfo.mzPrescriptionOut.userPhone }}】</div>
                  <div class="flex-start" style="position: relative; margin-right: 85px">
                    药剂师：
                    <el-image :src="`${path}${mZPrescriptionInfo.mzPrescriptionOut.auditSignImgPharmacist}`" style="width: 60px; height: 20px; position: absolute; top: 5px; left: 80px" fit="contain" v-if="mZPrescriptionInfo.mzPrescriptionOut.auditSignImgPharmacist"></el-image>
                  </div>
                </div>
                <!-- <div class="PrintBoxTitleLine"></div> -->
                <div>
                  <div style="position: relative">
                    审核医师：
                    <!-- <img :src="patientDetail.mzPrescriptionOut.auditSignImg" style="width: 300px; height: 100px" fit="cover " /> -->
                    <el-image :src="`${path}${mZPrescriptionInfo.mzPrescriptionOut.auditSignImg}`" style="width: 60px; height: 20px; position: absolute; top: 5px; left: 80px" fit="contain"></el-image>
                    <!-- <el-image :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImg" style="width: 150px; height: 50px" fit="cover "> </el-image> -->
                  </div>
                </div>
                <div>
                  <!-- <div>开具日期：{{ mZPrescriptionInfo.mzPrescriptionOut.auditTime | timefilters }}</div> -->
                </div>
                <div class="PrintBoxTitleLine"></div>
                <div>注意处方有效期3天、擅自下载打印无效</div>
              </div>
              <div style="page-break-after: always"></div>
            </div>
          </div>
          <div ref="pdfDomFJ" style="width: 600px; visibility: hidden" :class="isPrint ? 'printBox' : ''">
            <div class="PrintBox" style="width: 600px; background: #ffffff; font-size: 14px; padding: 20px 20px 0">
              <div class="top">
                <div class="PrintBoxTitle">门诊处方笺</div>
                <div class="PrintBoxTitle1">
                  <div>就诊日期：{{ mZPrescriptionInfo.mzPrescriptionOut.addTime | timefilters }}</div>
                  <div>病历号:{{ mZPrintPrescription.diagnoseNumber }}</div>
                </div>
                <div class="PrintBoxTitle2">
                  <div>姓名：{{ mZPrescriptionInfo.mzPrescriptionOut.patientName }}</div>
                  <div>手机号：{{ mZPrescriptionInfo.mzPrescriptionOut.patientPhone }}</div>
                  <div>性别:{{ mZPrescriptionInfo.mzPrescriptionOut.sex ? '男' : '女' }}</div>
                  <div>年龄:{{ mZPrescriptionInfo.mzPrescriptionOut.age }}</div>
                  <div>部门:{{ mZPrintPrescription.deparment }}</div>
                </div>
                <div class="PrintBoxTitleLine"></div>
                <div>
                  <div>
                    诊断:
                    {{ mZPrescriptionInfo.mzPrescriptionOut.diseaseDiagnose }}
                  </div>
                </div>
                <div class="PrintBoxTitleLineD"></div>
                <div style="font-size: 32px">R</div>
              </div>
              <div style="height: 440px">
                <div v-for="(item, index) in mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionYP" :key="index">
                  <span class="flex-l" style="margin-left: 15px; margin-top: 5px">
                    <span style="color: #999999">{{ item.prescriptionName }}</span>
                  </span>
                  <div class="flex-l" style="margin-left: 15px; margin-top: 5px; flex-wrap: wrap">
                    <span style="margin-right: 10px" v-for="(item1, index1) in item.mzPrescriptionDetail" :key="index1">
                      <span>{{ item1.drugOrgName }}</span>
                      <span>：{{ item1.weight }}g</span>
                      <span v-if="item1.remark">，{{ item1.remark }}</span>
                      <span v-if="item1.decoct">，{{ item1.decoct }};</span>
                    </span>
                  </div>
                  <div style="display: flex; align-items: center; margin-left: 15px">
                    <div>剂数：{{ item.dosage }}</div>
                    <div style="margin-left: 10px">每日剂量: {{ item.dosageNumber }}</div>
                  </div>
                  <div style="display: flex; align-items: center; margin-left: 15px">
                    <div>用药频率: {{ item.frequency }}</div>
                    <div style="margin-left: 10px">用法: {{ item.usage }}</div>
                  </div>
                  <div style="margin-left: 15px">
                    <div>服用要求：{{ item.request }}</div>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div>医嘱:{{ mZPrescriptionInfo.mzPrescriptionOut.doctorAdvice ? mZPrescriptionInfo.mzPrescriptionOut.doctorAdvice : '--' }}</div>
                <div class="PrintBoxTitleLine"></div>
                <div class="PrintBoxTitle2">
                  <div class="flex-start">医师：{{ detailInfo.doctorName ? detailInfo.doctorName : '无' }}【{{ mZPrescriptionInfo.mzPrescriptionOut.userPhone }}】</div>
                  <div class="flex-start" style="position: relative; margin-right: 85px">
                    药剂师：
                    <el-image :src="`${path}${mZPrescriptionInfo.mzPrescriptionOut.auditSignImgPharmacist}`" style="width: 60px; height: 20px; position: absolute; top: 5px; left: 80px" fit="contain" v-if="mZPrescriptionInfo.mzPrescriptionOut.auditSignImgPharmacist"></el-image>
                  </div>
                </div>
                <!-- <div class="PrintBoxTitleLine"></div> -->
                <div>
                  <div style="position: relative">
                    审核医师：
                    <!-- <img :src="patientDetail.mzPrescriptionOut.auditSignImg" style="width: 300px; height: 100px" fit="cover " /> -->
                    <el-image :src="`${path}${mZPrescriptionInfo.mzPrescriptionOut.auditSignImg}`" style="width: 60px; height: 20px; position: absolute; top: 5px; left: 80px" fit="contain"></el-image>
                    <!-- <el-image :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImg" style="width: 150px; height: 50px" fit="cover "> </el-image> -->
                  </div>
                </div>
                <div>
                  <!-- <div>开具日期：{{ mZPrescriptionInfo.mzPrescriptionOut.auditTime | timefilters }}</div> -->
                </div>
                <div class="PrintBoxTitleLine"></div>
                <div>注意处方有效期3天、擅自下载打印无效</div>
              </div>
              <div style="page-break-after: always"></div>
            </div>
          </div>
          <div ref="pdfDomCY" style="width: 600px; visibility: hidden" :class="isPrint ? 'printBox' : ''">
            <div class="PrintBox" style="width: 600px; background: #ffffff; font-size: 14px; padding: 20px 20px 0">
              <div class="top">
                <div class="PrintBoxTitle">门诊处方笺</div>
                <div class="PrintBoxTitle1">
                  <div>就诊日期：{{ mZPrescriptionInfo.mzPrescriptionOut.addTime | timefilters }}</div>
                  <div>病历号:{{ mZPrintPrescription.diagnoseNumber }}</div>
                </div>
                <div class="PrintBoxTitle2">
                  <div>姓名：{{ mZPrescriptionInfo.mzPrescriptionOut.patientName }}</div>
                  <div>手机号：{{ mZPrescriptionInfo.mzPrescriptionOut.patientPhone }}</div>
                  <div>性别:{{ mZPrescriptionInfo.mzPrescriptionOut.sex ? '男' : '女' }}</div>
                  <div>年龄:{{ mZPrescriptionInfo.mzPrescriptionOut.age }}</div>
                  <div>部门:{{ mZPrintPrescription.deparment }}</div>
                </div>
                <div class="PrintBoxTitleLine"></div>
                <div>
                  <div>
                    诊断:
                    {{ mZPrescriptionInfo.mzPrescriptionOut.diseaseDiagnose }}
                  </div>
                </div>
                <div class="PrintBoxTitleLineD"></div>
                <div style="font-size: 32px">R</div>
              </div>
              <div style="height: 440px">
                <div v-for="(item, index) in mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionCY" :key="index">
                  <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                    <div style="color: #999999">
                      {{ item.prescriptionName }}
                    </div>
                  </div>
                  <div style="margin-top: 5px">
                    <el-table class="printTab" :data="item.mzPrescriptionDetail">
                      <el-table-column prop="drugOrgName" align="center" label="药品名称"></el-table-column>
                      <el-table-column prop="usage" align="center" label="用法">
                        <template slot-scope="scope">
                          {{ scope.row.usage }}
                        </template>
                      </el-table-column>
                      <el-table-column prop="frequency" align="center" label="用药频率">
                        <template slot-scope="scope">
                          {{ scope.row.frequency }}
                        </template>
                      </el-table-column>
                      <el-table-column prop="dosage" align="center" label="单次剂量">
                        <template slot-scope="scope">
                          {{ scope.row.dosage + scope.row.dosageUnit }}
                        </template>
                      </el-table-column>
                      <el-table-column prop="defaultDosage" align="center" label="开药量">
                        <template slot-scope="scope">
                          {{ scope.row.defaultDosage + scope.row.defaultDosageUnit }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div>医嘱:{{ mZPrescriptionInfo.mzPrescriptionOut.doctorAdvice ? mZPrescriptionInfo.mzPrescriptionOut.doctorAdvice : '--' }}</div>
                <div class="PrintBoxTitleLine"></div>
                <div class="PrintBoxTitle2">
                  <div class="flex-start">医师：{{ detailInfo.doctorName ? detailInfo.doctorName : '无' }}【{{ mZPrescriptionInfo.mzPrescriptionOut.userPhone }}】</div>
                  <div class="flex-start" style="position: relative; margin-right: 85px">
                    药剂师：
                    <el-image :src="`${path}${mZPrescriptionInfo.mzPrescriptionOut.auditSignImgPharmacist}`" style="width: 60px; height: 20px; position: absolute; top: 5px; left: 80px" fit="contain" v-if="mZPrescriptionInfo.mzPrescriptionOut.auditSignImgPharmacist"></el-image>
                  </div>
                </div>
                <!-- <div class="PrintBoxTitleLine"></div> -->
                <div>
                  <div style="position: relative">
                    审核医师：
                    <!-- <img :src="patientDetail.mzPrescriptionOut.auditSignImg" style="width: 300px; height: 100px" fit="cover " /> -->
                    <el-image :src="`${path}${mZPrescriptionInfo.mzPrescriptionOut.auditSignImg}`" style="width: 60px; height: 20px; position: absolute; top: 5px; left: 80px" fit="contain"></el-image>
                    <!-- <el-image :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImg" style="width: 150px; height: 50px" fit="cover "> </el-image> -->
                  </div>
                </div>
                <div>
                  <!-- <div>开具日期：{{ mZPrescriptionInfo.mzPrescriptionOut.auditTime | timefilters }}</div> -->
                </div>
                <div class="PrintBoxTitleLine"></div>
                <div>注意处方有效期3天、擅自下载打印无效</div>
              </div>
              <div style="page-break-after: always"></div>
            </div>
          </div>
          <div ref="pdfDomSY" style="width: 600px; visibility: hidden" :class="isPrint ? 'printBox' : ''">
            <div class="PrintBox" style="width: 600px; background: #ffffff; font-size: 14px; padding: 20px 20px 0">
              <div class="top">
                <div class="PrintBoxTitle">门诊处方笺</div>
                <div class="PrintBoxTitle1">
                  <div>就诊日期：{{ mZPrescriptionInfo.mzPrescriptionOut.addTime | timefilters }}</div>
                  <div>病历号:{{ mZPrintPrescription.diagnoseNumber }}</div>
                </div>
                <div class="PrintBoxTitle2">
                  <div>姓名：{{ mZPrescriptionInfo.mzPrescriptionOut.patientName }}</div>
                  <div>手机号：{{ mZPrescriptionInfo.mzPrescriptionOut.patientPhone }}</div>
                  <div>性别:{{ mZPrescriptionInfo.mzPrescriptionOut.sex ? '男' : '女' }}</div>
                  <div>年龄:{{ mZPrescriptionInfo.mzPrescriptionOut.age }}</div>
                  <div>部门:{{ mZPrintPrescription.deparment }}</div>
                </div>
                <div class="PrintBoxTitleLine"></div>
                <div>
                  <div>
                    诊断:
                    {{ mZPrescriptionInfo.mzPrescriptionOut.diseaseDiagnose }}
                  </div>
                </div>
                <div class="PrintBoxTitleLineD"></div>
                <div style="font-size: 32px">R</div>
              </div>
              <div style="height: 440px">
                <div v-for="(item, index) in mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionSYGroup" :key="index">
                  <div class="flex-l" style="margin-left: 15px; margin-top: 5px">
                    <div style="color: #999999">
                      {{ item.prescriptionName }}
                    </div>
                  </div>
                  <div v-for="(item1, index1) in item.mzPrescriptionGroups" :key="index1">
                    <div style="margin-left: 15px; margin-top: 5px">
                      <div style="color: #999999">组{{ index1 + 1 }}</div>
                      <div>
                        <span style="margin-right: 10px">用法：{{ item1.usage }};</span>
                        <span style="margin-right: 10px">用药频率：{{ item1.frequency }};</span>
                        <span style="margin-right: 10px">滴速 {{ item1.drippingSpeed }}{{ item1.drippingSpeedUnit }};</span>
                        <span style="margin-right: 10px">起始日期：{{ item1.startTime ? item1.startTime.slice(0, 10) : '无' }};</span>
                        <span style="margin-right: 10px">连续天数：{{ item1.days }}</span>
                      </div>
                    </div>
                    <div v-for="(item2, index2) in item1.mzPrescriptionDetailSY" :key="index2" style="margin-left: 15px; margin-top: 5px">
                      <div></div>
                      <div>
                        <span> 名称：{{ item2.drugOrgName }};皮试：{{ item2.st }};单次剂量：{{ item2.dosage }}{{ item2.dosageUnit }};开药量：{{ item2.defaultDosage }}{{ item2.defaultDosageUnit }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div>医嘱:{{ mZPrescriptionInfo.mzPrescriptionOut.doctorAdvice ? mZPrescriptionInfo.mzPrescriptionOut.doctorAdvice : '--' }}</div>
                <div class="PrintBoxTitleLine"></div>
                <div class="PrintBoxTitle2">
                  <div class="flex-start">医师：{{ detailInfo.doctorName ? detailInfo.doctorName : '无' }}【{{ mZPrescriptionInfo.mzPrescriptionOut.userPhone }}】</div>
                  <div class="flex-start" style="position: relative; margin-right: 85px">
                    药剂师：
                    <el-image :src="`${path}${mZPrescriptionInfo.mzPrescriptionOut.auditSignImgPharmacist}`" style="width: 60px; height: 20px; position: absolute; top: 5px; left: 80px" fit="contain" v-if="mZPrescriptionInfo.mzPrescriptionOut.auditSignImgPharmacist"></el-image>
                  </div>
                </div>
                <!-- <div class="PrintBoxTitleLine"></div> -->
                <div>
                  <div style="position: relative">
                    审核医师：
                    <!-- <img :src="patientDetail.mzPrescriptionOut.auditSignImg" style="width: 300px; height: 100px" fit="cover " /> -->
                    <el-image :src="`${path}${mZPrescriptionInfo.mzPrescriptionOut.auditSignImg}`" style="width: 60px; height: 20px; position: absolute; top: 5px; left: 80px" fit="contain"></el-image>
                    <!-- <el-image :src="Services.Authorization + patientDetail.mzPrescriptionOut.auditSignImg" style="width: 150px; height: 50px" fit="cover "> </el-image> -->
                  </div>
                </div>
                <div>
                  <!-- <div>开具日期：{{ mZPrescriptionInfo.mzPrescriptionOut.auditTime | timefilters }}</div> -->
                </div>
                <div class="PrintBoxTitleLine"></div>
                <div>注意处方有效期3天、擅自下载打印无效</div>
              </div>
              <div style="page-break-after: always"></div>
            </div>
          </div>
          <div ref="payList" style="width: 600px; visibility: hidden" :class="isPrint ? 'printBox2' : ''">
            <div class="print-d">
              <div class="p-t al">收费凭证</div>
              <div class="oneLine2"></div>
              <div>病历号:{{ mZPrintPrescription.diagnoseNumber }}</div>
              <div v-if="detailInfo.payments && detailInfo.payments.length > 0">收费时间：{{ detailInfo.payments[0].paySuccessTime | timefilters }}</div>
              <div v-if="detailInfo.payments && detailInfo.payments.length > 0">收费员：{{ detailInfo.payments[0].userName }}</div>
              <div class="twoLine"></div>
              <div class="flex-space-between">
                <div class="text-l">姓名：{{ mZPrintPrescription.patientName }}</div>
                <div class="text-r">年龄：{{ mZPrintPrescription.patientAge }}</div>
              </div>
              <div>电话：{{ mZPrintPrescription.patientPhone }}</div>
              <div>诊断结果：{{ mZPrescriptionInfo.mzPrescriptionOut.diseaseDiagnose }}</div>
              <div class="oneLine" v-if="orderGrouped[0].length > 0"></div>
              <!-- 直接售药 -->
              <el-table class="printTab" :data="orderGrouped[0]" style="width: 100%" :header-cell-style="{ background: '#fff' }" v-if="orderGrouped[0].length > 0">
                <el-table-column prop="entityName" label="药品名称">
                  <template slot-scope="scope">
                    {{ scope.row.entityName }}
                    <span v-if="scope.row.drugType == 'T'">(统)</span>
                    <span v-if="scope.row.drugType == 'X'">(选)</span>
                    <span v-if="scope.row.drugType == 'J'">(精选)</span>
                  </template>
                </el-table-column>
                <el-table-column prop="amount" label="数量" align="center">
                  <template slot-scope="scope"> {{ scope.row.amount }}{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
                </el-table-column>
                <el-table-column prop="money" label="单价(元)" align="right">
                  <template slot-scope="scope"> {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
                </el-table-column>
              </el-table>
              <div class="oneLine" v-if="prescriptionGrouped[1].length > 0"></div>
              <!-- 成药处方 -->
              <el-table class="printTab" :data="prescriptionGrouped[1]" style="width: 100%" :header-cell-style="{ background: '#fff' }" v-if="prescriptionGrouped[1].length > 0">
                <el-table-column prop="entityName" label="药品名称"></el-table-column>
                <el-table-column prop="amount" label="数量" align="center">
                  <template slot-scope="scope"> {{ scope.row.amount }}{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
                </el-table-column>
                <el-table-column prop="money" label="单价(元)" align="right">
                  <template slot-scope="scope"> {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
                </el-table-column>
              </el-table>
              <div class="oneLine" v-if="prescriptionGrouped[2].length > 0"></div>
              <!-- 输液处方 -->
              <el-table class="printTab" :data="prescriptionGrouped[2]" style="width: 100%" :header-cell-style="{ background: '#fff' }" v-if="prescriptionGrouped[2].length > 0">
                <el-table-column prop="entityName" label="药品名称"></el-table-column>
                <el-table-column prop="amount" label="数量" align="center">
                  <template slot-scope="scope"> {{ scope.row.amount }}{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
                </el-table-column>
                <el-table-column prop="money" label="单价(元)" align="right">
                  <template slot-scope="scope"> {{ scope.row.money.toFixed(2) }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
                </el-table-column>
              </el-table>
              <div class="oneLine" v-if="mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionYP.length > 0"></div>
              <!-- 方剂处方 -->
              <el-table class="printTab" :data="mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionYP" style="width: 100%" :header-cell-style="{ background: '#fff' }" v-if="mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionYP.length > 0">
                <el-table-column prop="prescriptionName" label="饮片名称"></el-table-column>
                <el-table-column prop="dosage" label="数量" align="center"></el-table-column>
                <el-table-column prop="prescriptionMoney" label="总金额(元)" align="right"></el-table-column>
              </el-table>
              <div class="oneLine" v-if="orderGrouped[88].length > 0"></div>
              <!-- 代煎费 -->
              <el-table class="printTab" :data="orderGrouped[88]" style="width: 540px" :header-cell-style="{ background: '#fff' }" v-if="orderGrouped[88].length > 0">
                <el-table-column prop="entityName" label="名称"></el-table-column>
                <el-table-column prop="countMoney" label="金额(元)" align="right"></el-table-column>
              </el-table>
              <div class="oneLine" v-if="orderGrouped[99].length > 0"></div>
              <!-- 代煎费 -->
              <el-table class="printTab" :data="orderGrouped[99]" style="width: 540px" :header-cell-style="{ background: '#fff' }" v-if="orderGrouped[99].length > 0">
                <el-table-column prop="entityName" label="名称"></el-table-column>
                <el-table-column prop="countMoney" label="金额(元)" align="right"></el-table-column>
              </el-table>
              <div class="twoLine"></div>
              <div class="text-r" style="font-size: 20px">实付：{{ detailInfo.totalMoney ? detailInfo.totalMoney.toFixed(2) : '0.00' }}元</div>
            </div>
          </div>
          <div ref="useDesc" style="width: 600px; visibility: hidden" :class="isPrint ? 'printBox3' : ''">
            <div class="print-d">
              <div class="p-t al">服用说明</div>
              <div class="oneLine2"></div>
              <div style="color: #333333">
                <div class="flex-space-between">
                  <div class="text-l">姓名：{{ mZPrescriptionInfo.mzPrescriptionOut.patientName }}</div>
                  <div class="text-r">年龄：{{ mZPrescriptionInfo.mzPrescriptionOut.age }}</div>
                </div>
                <div>电话：{{ mZPrescriptionInfo.mzPrescriptionOut.patientPhone }}</div>
                <div>诊断结果：{{ mZPrescriptionInfo.mzPrescriptionOut.diseaseDiagnose }}</div>
              </div>
              <div class="oneLine3" v-if="orderGrouped[0].length > 0"></div>
              <!-- 直接售药 -->
              <div v-if="orderGrouped[0].length > 0">
                <div>成药</div>
                <div v-for="(item, cy) in orderGrouped[0]" :key="cy">
                  <span>{{ item.entityName }}</span>
                  <span>，{{ item.amount }}{{ item.isSeparate ? item.separateNumberUnit : item.specificationUnit }}</span>
                </div>
              </div>
              <div class="oneLine3" v-if="mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionCY.length > 0"></div>
              <!-- 成药处方 -->
              <template v-if="mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionCY.length > 0">
                <div v-for="item1 in mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionCY">
                  <div class="flex-l">
                    <div style="">{{ item1.prescriptionName }}</div>
                  </div>
                  <div v-for="(item, index1) in item1.mzPrescriptionDetail" :key="index1">
                    <div>{{ item.drugOrgName }}，{{ item.defaultDosage }}{{ item.defaultDosageUnit }}；</div>
                    <div class="flex-space-between">
                      <div>单次剂量：{{ item.dosage }}{{ item.dosageUnit }}</div>
                      <div>用法： {{ item.usage }}</div>
                    </div>
                    <div>用药频率：{{ item.frequency }}</div>
                  </div>
                </div>
              </template>
              <!-- 输液处方 -->
              <template v-if="mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionSYGroup.length > 0">
                <div class="oneLine3"></div>
                <div v-for="(item, b) in mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionSYGroup" :key="b">
                  <div class="flex-l">
                    <div style="">{{ item.prescriptionName }}</div>
                  </div>
                  <div v-for="(item1, index1) in item.mzPrescriptionGroups" :key="index1">
                    <div style="">组{{ index1 + 1 }}</div>
                    <span style="margin-right: 10px">用法：{{ item1.usage }};</span>
                    <span style="margin-right: 10px">用药频率：{{ item1.frequency }};</span>
                    <span style="margin-right: 10px">滴速 {{ item1.drippingSpeed }}{{ item1.drippingSpeedUnit }};</span>
                    <span style="margin-right: 10px">起始日期：{{ item1.startTime ? item1.startTime.slice(0, 10) : '无' }};</span>
                    <div style="margin-right: 10px">连续天数：{{ item1.days }}</div>
                    <div v-for="(item2, index2) in item1.mzPrescriptionDetailSY" :key="index2">
                      <div>
                        <span>{{ item2.drugOrgName }};皮试：{{ item2.st }};单次剂量：{{ item2.dosage }}{{ item2.dosageUnit }};开药量：{{ item2.defaultDosage }}{{ item2.defaultDosageUnit }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!-- 方剂处方 -->
              <template v-if="mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionYP.length > 0">
                <div class="oneLine3"></div>
                <div v-for="(item, c) in mZPrescriptionInfo.mzPrescriptionOut.mzPrescriptionYP" :key="c">
                  <span class="flex-l">
                    <span style="">{{ item.prescriptionName }}</span>
                  </span>
                  <div style="display: flex; align-items: center">
                    <div>剂数：{{ item.dosage }}剂</div>
                    <div style="margin-left: 10px">每日剂量: {{ item.dosageNumber }}剂</div>
                  </div>
                  <div style="display: flex; align-items: center">
                    <div>用药频率: {{ item.frequency }}</div>
                    <div style="margin-left: 10px">用法: {{ item.usage }}</div>
                  </div>
                  <div>服用要求：{{ item.request }}</div>
                </div>
              </template>
              <div>医嘱:{{ mZPrescriptionInfo.mzPrescriptionOut.doctorAdvice ? mZPrescriptionInfo.mzPrescriptionOut.doctorAdvice : '--' }}</div>
              <div class="twoLine"></div>
              <div class="flex-start">审核医生：{{ mZPrintPrescription.doctorName }}【{{ mZPrintPrescription.doctorPhone }}】</div>
              <!-- <div class="flex-start">药剂师：{{ mZPrintPrescription.pharmacistName }}【{{ mZPrintPrescription.pharmacistPhone }}】</div> -->
              <div class="oneLine3" v-if="!detailInfo.isSelef"></div>
              <div v-if="!detailInfo.isSelef">
                <div class="flex-start">配送地址：{{ detailInfo.takeAddress ? detailInfo.takeAddress : '无' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="DialogTitle" :visible.sync="setDialog" width="30%">
      <el-form class="dialog-form" :model="charge" ref="chargeForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="应收金额" prop="num" style="color: #ff0000" class="numT">
          {{ charge.num ? charge.num.toFixed(2) : '0.00' }}
        </el-form-item>
        <el-form-item label="挂账">
          <el-switch v-model="charge.credit"></el-switch>
        </el-form-item>
        <template v-if="charge.credit">
          <el-form-item label="姓名" prop="userName">
            <el-input v-model="charge.userName" style="width: 80%"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="userPhone" style="width: 80%">
            <el-input type="number" v-model="charge.userPhone" length="11"></el-input>
          </el-form-item>
          <el-form-item label="欠款金额" prop="creditNum">
            <el-input-number v-model="charge.creditNum" size="small" controls-position="right" :precision="2" :step="0.1" :min="1" class="leftInput"></el-input-number>
          </el-form-item>
        </template>
        <el-form-item label="付款方式" prop="type">
          <el-radio-group v-model="charge.type">
            <el-radio :label="1">现金</el-radio>
            <el-radio :label="2">微信</el-radio>
            <el-radio :label="3">支付宝</el-radio>
            <el-radio :label="4">银行卡</el-radio>
            <el-radio :label="5">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="付款金额" prop="payNum">
          <el-input-number v-model="charge.payNum" size="small" controls-position="right" :precision="2" :step="0.1" :min="1" class="leftInput"></el-input-number>
        </el-form-item>
        <el-form-item label="找零">
          {{ charge.change ? charge.change.toFixed(2) : '0.00' }}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('chargeForm')">取 消</el-button>
        <el-button type="primary" @click="SaveBtn('bankCardForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient'
import { Patient } from '@/components/HospitalDomain/Patient/Patient'
import printJS from 'print-js'
import html2Canvas from 'html2canvas'
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    var patient = new Patient(this.TokenClient, this.Services.Authorization)
    return {
      OutpatientDomain: outpatient,
      patientDomain: patient,
      tableData: [],
      detailInfo: {
        gender: 0,
      },
      DialogTitle: '收费',
      setDialog: false,
      formLabelWidth: '100px',
      charge: {
        num: 12,
        type: 1,
        payNum: 12,
        change: 0,
      },
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      orderId: this.$route.query.orderId,
      type: this.$route.query.type, //1收费2退费
      form: {
        name: '',
        type: [],
        drug: [
          {
            edit: true,
            name: '',
            guidancePrice: null,
            membershipPrice: null,
            ordinaryPrice: null,
            stockNum: null,
            rank: null,
          },
        ],
      },
      rules: {
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        coverPicture: [
          {
            required: true,
            type: 'array',
            message: '请上传商品封面',
            trigger: 'blur',
          },
        ],
        memberShare: [
          {
            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/,
            message: '小数点后保留两位',
            trigger: 'blur',
          },
          { required: true, message: '请输入正确的金额', trigger: 'blur' },
        ],
        ordinaryShare: [
          {
            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/,
            message: '小数点后保留两位',
            trigger: 'blur',
          },
          { required: true, message: '请输入正确的金额', trigger: 'blur' },
        ],
        namegg: [{ required: true, message: '请输入规格名称', trigger: 'blur' }],
        guidancePrice: [
          {
            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/,
            message: '小数点后保留两位',
            trigger: 'blur',
          },
          { required: true, message: '请输入商品指导价', trigger: 'blur' },
        ],
        doctorPrice: [
          {
            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/,
            message: '小数点后保留两位',
            trigger: 'blur',
          },
          { required: true, message: '请输入商品价格', trigger: 'blur' },
        ],
        membershipPrice: [
          {
            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/,
            message: '小数点后保留两位',
            trigger: 'blur',
          },
          { required: true, message: '请输入商品会员价', trigger: 'blur' },
        ],
        ordinaryPrice: [
          {
            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/,
            message: '小数点后保留两位',
            trigger: 'blur',
          },
          { required: true, message: '请输入商品非会员价', trigger: 'blur' },
        ],
        categoryId: [{ required: true, message: '请选择商品分类', trigger: 'change' }],
        stockNum: [
          {
            pattern: /^[1-9]\d*$/,
            message: '请输入大于0，小于10000的正整数',
            trigger: 'blur',
          },
          { required: true, message: '请输入库存量', trigger: 'blur' },
        ],
        rank: [
          { pattern: /^[0-9]\d*$/, message: '请输入整数', trigger: 'blur' },
          { required: true, message: '请输入库存量', trigger: 'blur' },
        ],
        cancellationOrder: [
          {
            pattern: /^[1-9]\d*$/,
            message: '请输入大于0，小于10000的正整数',
            trigger: 'blur',
          },
          { required: true, message: '请输入正确的分钟数', trigger: 'blur' },
        ],
        completionOrder: [
          {
            pattern: /^[1-9]\d*$/,
            message: '请输入大于0，小于10000的正整数',
            trigger: 'blur',
          },
          { required: true, message: '请输入正确的天数', trigger: 'blur' },
        ],
        originId: [
          {
            type: 'array',
            required: true,
            message: '请选择产地',
            trigger: 'change',
          },
        ],
        introduce: [{ required: true, message: '请输入商品介绍', trigger: 'change' }],
        // instructions: [
        //     { required: true, message: '请输入购买须知', trigger: 'change' },
        // ],
      },
      orderGrouped: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 88: [], 99: [] },
      patientId: 0,
      patientDiagnoseId: 0,
      patientInfo: {},
      patientDiagnoseInfo: {},
      mZPrescriptionInfo: {
        mzCheckItems: [],
        mzOtherCharges: [],
        mzPrescriptionOut: {
          mzPrescriptionSYGroup: [],
          mzPrescriptionCY: [],
          mzPrescriptionYP: [],
        },
      },
      mZPrintPrescription: {},
      isPrint: false,
      //moment: require('moment'),
      prescriptionGrouped: { 0: [], 1: [], 2: [] },
      path: this.Services.Authorization,
      isShow: false,
      totalSale: 0,
      totalFJ: 0,
      totalCY: 0,
      totalSY: 0,
    }
  },
  mounted() {
    if (this.type == 1) {
      this.paymentedDetail()
    } else {
      this.drugRefundDetail()
    }
  },
  methods: {
    toPrint(div) {
      this.isPrint = true
      this.$nextTick(() => {
        html2Canvas(div, {
          allowTaint: true,
          taintTest: false,
          useCORS: true,
          dpi: window.devicePixelRatio * 4,
          scale: 4,
        }).then((canvas) => {
          const url = canvas.toDataURL()
          printJS({
            printable: url, // 要打印的id
            type: 'image',
            style: '@media print{ @page { size: A4; margin: 0mm -1.5mm; mso-header: none; mso-footer: none;  } body{margin:0 5px}}', //去除页眉页脚
          })
          this.isPrint = false
        })
      })
    },
    /**
     * 已收费详情
     */
    paymentedDetail() {
      var _this = this
      _this.OutpatientDomain.PaymentedDetail(
        this.orderId,
        function (data) {
          _this.detailInfo = data.data
          _this.patientId = _this.detailInfo.patientId
          _this.patientDiagnoseId = _this.detailInfo.patientDiagnoseId
          if (_this.patientId) {
            _this.getDetail()
            _this.getPatientDiagnose()
            _this.getMZPrescriptionDetail()
          }
          //orderType 0直接售药 1处方 2检查治疗项 3附加收费 4代煎费用 5审方费用 6快递费 7包装费 8药剂师审方费用
          _this.orderGrouped = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 88: [], 99: [] }
          if (_this.detailInfo.details.length > 0) {
            _this.detailInfo.details.map(({ orderType, ...rest }) => {
              _this.orderGrouped[orderType] = _this.orderGrouped[orderType] || []
              rest.orderType = orderType
              if ((orderType == 0 || orderType == 1) && _this.detailInfo.mzDrugOrgs) {
                let drugOrg = _this.detailInfo.mzDrugOrgs.filter((i) => i.id == rest.entityId)
                if (drugOrg[0]) {
                  rest.drugType = drugOrg[0].mzDrug.drugType
                  rest.specification = drugOrg[0].specification
                  rest.specificationUnit = drugOrg[0].specificationUnit
                  rest.productFactory = drugOrg[0].productFactory
                  rest.isSeparate = drugOrg[0].isSeparate
                  rest.separateNumber = drugOrg[0].separateNumber
                  rest.separateNumberUnit = drugOrg[0].separateNumberUnit
                  rest.remark = drugOrg[0].remark
                }
              }
              _this.orderGrouped[orderType].push(rest)
              if (orderType == 5 || orderType == 8) {
                _this.orderGrouped[88].push(rest)
              }

              if (orderType == 3 || orderType == 4 || orderType == 6 || orderType == 7) {
                _this.orderGrouped[99].push(rest)
              }
            })
          }
          if (_this.orderGrouped[0].length > 0) {
            _this.totalSale = _this.orderGrouped[0].reduce((sum, i) => sum + i.countMoney, 0).toFixed(2)
          }
          _this.prescriptionGrouped = { 0: [], 1: [], 2: [], 3: [] }
          //prescriptionType 0方剂处方 1成药处方 2输液处方
          if (_this.orderGrouped[1].length > 0) {
            _this.orderGrouped[1].map(({ prescriptionType, ...rest }) => {
              _this.prescriptionGrouped[prescriptionType] = _this.prescriptionGrouped[prescriptionType] || []
              rest.prescriptionType = prescriptionType
              _this.prescriptionGrouped[prescriptionType].push(rest)
            })
          }
          if (_this.prescriptionGrouped[0].length > 0) {
            _this.totalFJ = _this.prescriptionGrouped[0].reduce((sum, i) => sum + i.countMoney * i.dosage, 0).toFixed(2)
          }

          if (_this.prescriptionGrouped[1].length > 0) {
            _this.totalCY = _this.prescriptionGrouped[0].reduce((sum, i) => sum + i.countMoney, 0).toFixed(2)
          }
          if (_this.prescriptionGrouped[2].length > 0) {
            _this.totalSY = _this.prescriptionGrouped[0].reduce((sum, i) => sum + i.countMoney, 0).toFixed(2)
          }
        },
        function (err) {
          console.log(err)
        }
      )
    },
    /**
     * 已退费详情
     */
    drugRefundDetail() {
      var _this = this
      _this.OutpatientDomain.DrugRefundDetail(
        this.orderId,
        function (data) {
          _this.detailInfo = data.data
          _this.patientId = _this.detailInfo.patientId
          _this.patientDiagnoseId = _this.detailInfo.patientDiagnoseId
          if (_this.patientId) {
            _this.getDetail()
            _this.getPatientDiagnose()
            _this.getMZPrescriptionDetail()
          }
          _this.orderGrouped = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 88: [], 99: [] }
          if (_this.detailInfo.details.length > 0) {
            _this.detailInfo.details.map(({ orderType, ...rest }) => {
              _this.orderGrouped[orderType] = _this.orderGrouped[orderType] || []
              rest.orderType = orderType
              if ((orderType == 0 || orderType == 1) && _this.detailInfo.mzDrugOrgs) {
                let drugOrg = _this.detailInfo.mzDrugOrgs.filter((i) => i.id == rest.entityId)
                rest.drugType = drugOrg[0].mzDrug.drugType
                rest.specification = drugOrg[0].specification
                rest.specificationUnit = drugOrg[0].specificationUnit
                rest.productFactory = drugOrg[0].productFactory
                rest.isSeparate = drugOrg[0].isSeparate
                rest.separateNumber = drugOrg[0].separateNumber
                rest.separateNumberUnit = drugOrg[0].separateNumberUnit
              }
              _this.orderGrouped[orderType].push(rest)
              if (orderType == 5 || orderType == 8) {
                _this.orderGrouped[88].push(rest)
              }

              if (orderType == 3 || orderType == 4 || orderType == 6 || orderType == 7) {
                _this.orderGrouped[99].push(rest)
              }
            })
          }
          if (_this.orderGrouped[0].length > 0) {
            _this.totalSale = _this.orderGrouped[0].reduce((sum, i) => sum + i.countMoney, 0).toFixed(2)
          }
          _this.prescriptionGrouped = { 0: [], 1: [], 2: [], 3: [] }
          //prescriptionType 0方剂处方 1成药处方 2输液处方
          if (_this.orderGrouped[1].length > 0) {
            _this.orderGrouped[1].map(({ prescriptionType, ...rest }) => {
              _this.prescriptionGrouped[prescriptionType] = _this.prescriptionGrouped[prescriptionType] || []
              rest.prescriptionType = prescriptionType
              _this.prescriptionGrouped[prescriptionType].push(rest)
            })
          }
          if (_this.prescriptionGrouped[0].length > 0) {
            _this.totalFJ = _this.prescriptionGrouped[0].reduce((sum, i) => sum + i.countMoney * i.dosage, 0).toFixed(2)
          }
          if (_this.prescriptionGrouped[1].length > 0) {
            _this.totalCY = _this.prescriptionGrouped[0].reduce((sum, i) => sum + i.countMoney, 0).toFixed(2)
          }
          if (_this.prescriptionGrouped[2].length > 0) {
            _this.totalSY = _this.prescriptionGrouped[0].reduce((sum, i) => sum + i.countMoney, 0).toFixed(2)
          }
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getDetail() {
      var _this = this
      _this.patientDomain.GetDetail(
        _this.patientId,
        function (data) {
          _this.patientInfo = data.data
        },
        function (err) {}
      )
    },
    getPatientDiagnose() {
      var _this = this
      _this.patientDomain.GetPatientDiagnoseDetail(
        _this.patientDiagnoseId,
        function (data) {
          _this.patientDiagnoseInfo = data.data
        },
        function (err) {}
      )
    },
    getMZPrescriptionDetail() {
      var _this = this
      _this.OutpatientDomain.MZPrescriptionDetail(
        _this.patientDiagnoseId,
        function (data) {
          _this.mZPrescriptionInfo = data.data
          _this.getPrintPrescription()
        },
        function (err) {}
      )
    },
    getPrintPrescription() {
      var _this = this
      _this.patientDomain.MZPrintPrescription(
        _this.patientDiagnoseId,
        this.mZPrescriptionInfo.mzPrescriptionOut.waitAuditUserId ? this.mZPrescriptionInfo.mzPrescriptionOut.waitAuditUserId : this.mZPrescriptionInfo.mzPrescriptionOut.userId,
        this.mZPrescriptionInfo.mzPrescriptionOut.waitAuditPharmacistId,
        function (data) {
          _this.mZPrintPrescription = data.data
        },
        function (err) {}
      )
    },
    toRefund() {
      this.$router.push({
        path: './ChargedOrRefund',
        query: {
          type: 2,
          orderId: this.orderId,
        },
      })
    },
    toCharge() {
      this.$router.push({
        path: './ChargedOrRefund',
        query: {
          type: 1,
          orderId: this.orderId,
        },
      })
    },
    CloseBtn(formName) {
      this.setDialog = false
      this.$refs[formName].resetFields()
    },
    saveData() {},
    handleReset() {
      let row = {
        edit: true,
        name: '',
        guidancePrice: null,
        membershipPrice: null,
        ordinaryPrice: null,
        stockNum: null,
        rank: null,
      }
      this.form.drug = []
      this.form.drug.push(row)
    },
    handleAdd() {
      let row = {
        edit: true,
        name: '',
        guidancePrice: null,
        membershipPrice: null,
        ordinaryPrice: null,
        stockNum: null,
        rank: null,
      }
      this.form.drug.push(row)
    },
    handleDelete(row, index) {
      if (this.form.drug.length == 1) {
        this.$message({
          message: '请至少保留一行',
          type: 'warning',
        })
        return
      }
      this.$message({
        message: '删除成功',
        type: 'success',
      })
      this.form.drug.splice(index, 1)
    },
    goBack() {
      this.$router.go(-1)
    },
    addPatient() {
      this.$router.push({
        path: './AddPatient',
        query: {},
      })
    },
  },
}
</script>
<style scoped lang="scss">
.iq-card-body {
  padding: 0 0 15px;
  height: auto;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {
    padding: 0 40px;

    .top-info {
      .t1 {
        margin-right: 30px;
      }

      .t2 {
        color: #999999;
      }

      .center-i {
        // margin: 0 40px;
      }

      .right-img {
        margin-right: 10%;
      }

      .userName {
        margin-left: 10px;
        font-size: 20px;
        color: var(--base--Color);
      }

      .info-div {
        margin-top: 10px;
      }
    }

    .title {
      height: 60px;
      line-height: 60px;
      font-weight: bold;
    }

    ::v-deep .el-table__row > td {
      /* 去除表格线 */
      border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    .print-d {
      ::v-deep .printTab .el-table__body-wrapper .el-table__cell {
        padding: 2px 0 !important;
      }
    }

    ::v-deep .el-table .el-table__body-wrapper .el-table__cell {
      padding: 10px 0 !important;
    }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }

    .el-form-item .el-form-item {
      margin-bottom: 20px;
    }

    .leftInput {
      width: 100%;
      position: relative;
    }

    .rightTip {
      position: absolute;

      top: 0;
      z-index: 2;
      color: #999999;
    }

    ::v-deep .el-input-number .el-input__inner {
      text-align: left;
    }

    .numDiv {
      padding: 15px;
      background: #fafafa;
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      margin: 20px 0;

      .right-num {
        // margin-right: 10%;
      }

      .center-num {
        // margin: 0 20px;
      }

      .desc {
        margin-right: 2%;
        width: 32%;
      }

      .desc:nth-child(3n) {
        margin-right: 0;
      }

      .type0 {
        color: #67c23a !important;
      }

      .type1 {
        color: #f56c6c !important;
      }

      .type2 {
        color: #e6a23c !important;
      }

      .t1 {
        margin-right: 20px;
      }

      .t2 {
        color: #999999;
      }

      .t3 {
        color: #fe0000;
      }

      .num1 {
        margin: 0 10px;
      }

      ::v-deep .num2 .el-input__inner {
        color: #ff0000;
      }

      .inputNum {
        color: #ff0000;
      }
    }

    .numDiv2 {
      padding: 15px;
      background: #fff7f7;
      border: 1px solid #f4d3d3;
      border-radius: 8px;
      margin: 20px 0;

      .num1 {
        margin: 0 10px;
      }

      .num2 {
        color: #ff0000;
      }

      ::v-deep .num2 .el-input__inner {
        color: #ff0000;
      }

      .inputNum {
        color: #ff0000;
      }
    }

    .infoDiv {
      // margin: 0 20px;

      .t1 {
        margin-right: 20px;
      }

      .t2 {
        color: #5c83f2;
      }
    }
  }
}

.PrintBox {
  width: 600px;
  background: #ffffff;
  font-size: 14px;
  padding: 20px;
  page-break-after: always !important;
  color: #333333;
}

.PrintBoxTitle {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}

.PrintBoxTitle1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PrintBoxTitle2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.PrintBoxTitleLine {
  width: 100%;
  height: 1px;
  background: #666666;
  margin: 8px 0 0 2px;
  // margin-top: -2px;
  margin-right: 10px;
}

.PrintBoxTitleLineD {
  margin: 15px 0;
  border-bottom: 1px dashed #999999;
}

.printBox {
  visibility: visible !important;
  background-color: #adadad;
  color: #333333;
}

.printBox2 {
  visibility: visible !important;
  color: #333333;
}

.printBox3 {
  visibility: visible !important;
  color: #333333;
}

.print-d {
  width: 90%;
  margin: 0 auto;
  background: #ffffff;
  font-size: 14px;

  .p-t {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding-top: 20px;
    color: #333333;
  }

  .oneLine {
    margin: 10px 0 0 0;
    border-bottom: 1px dashed #999999;
  }

  .oneLine2 {
    margin: 10px 0;
    border-bottom: 1px solid #999999;
  }

  .oneLine3 {
    margin: 10px 0;
    border-bottom: 1px dashed #999999;
  }

  .twoLine {
    margin: 10px 0;
    height: 3px;
    border-top: 1px dashed #999999;
    border-bottom: 1px dashed #999999;
  }
}
</style>
